import React, {useState, useEffect} from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"
import sanitizeHtml from "sanitize-html"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AgendaPage = (props) => {
  const intl = useIntl()
  // Client-side Runtime Data Fetching
  const [agendaData, setAgendaData] = useState('<p class="loading">Loading...</p>')
  useEffect(() => {

    var fetchUrl = props.location.origin + "/agenda_data.json"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)

    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        setAgendaData(resultData.value)
      })
  })


  /* sanitize-html default options */
  const options = {
    allowedTags: [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'abbr', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe' ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: [ 'href', 'name', 'target' ],
      p: [ 'class' ],
      // We don't currently allow img itself by default, but this
      // would make sense if we did. You could add srcset here,
      // and if you do the URL is checked for safety
      img: [ 'src' ]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    // URL schemes we permit
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  }
  return (
    <Layout title="Agenda">
      <SEO
        lang={intl.locale}
        title="Agenda"
      />
      <div css={css`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      `}>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(agendaData, options) }} 
            css={css`text-align:center; line-height: 0.9em;`}></div>
      </div>
    </Layout>
  )
}

export default AgendaPage